<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="UserIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Instructorial Information</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col w-1/2 ">

            <vs-input class="w-full mt-4" label="Instructor Title" name="title" v-model="data_local.title"/>
            <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>

          </div>

          <div class="vx-col w-1/2">
            <div class="mt-4">

              <div class="vx-col w-full mt-4">
                <vs-textarea height="125px" class="w-full" label="Instructor Description" name="description" v-model="data_local.description"/>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    initiateUserUpdate() {

      const payload = {
        title: this.data_local.title,
        description: this.data_local.description,
      };

      this.$vs.loading();

      this.$http.post(`users/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            this.data_local.gender = this.data_local.gender.toLowerCase();

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          if (exception.response) {
            const response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-user-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>
